<template>
  <v-row style="margin: 0">
    <styling @info="setInfo"/>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row v-if="$vuetify.breakpoint.mdAndDown && logo">
            <v-col class="text-center">
              <img style="width:35%" :src="logo" />
            </v-col>
          </v-row>
          <v-row v-if="show_login">
            <v-col cols="12" lg="9" xl="6">
              <h2 class="font-weight-bold mt-4">
                {{ $store.getters.translate("login") }}
              </h2>
              <h6 v-if="support_email" class="subtitle-1">
                {{ $store.getters.translate("no_account") }}
                <a :href="'mailto:' + support_email" class>{{
                  $store.getters.translate("get_help")
                }}</a>
              </h6>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  class="mt-4"
                  required
                  outlined/>
                <v-text-field
                  v-model="password"
                  :label="$store.getters.translate('password')"
                  required
                  outlined
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  color="primary"/>
                <v-alert v-if="message" dense outlined type="error">
                  {{ message }}
                </v-alert>
                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                  <div class="mb-4">
                    <a @click="$router.push('/resetpassword')">{{
                      $store.getters.translate("forgot_password")
                    }}</a>
                  </div>
                </div>
                <v-btn
                  @click="submit"
                  :loading="loading"
                  :disabled="!valid"
                  color="primary"
                  block
                  class="mr-4"
                  submit>
                  {{ $store.getters.translate("login") }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" lg="9" xl="6">
              {{ $store.getters.translate('verification_email_sent') }} {{ email }}. {{ $store.getters.translate('verify_code') }}
              <v-text-field :label="$store.getters.translate('verification_code')" v-model="verification_code"/>
              <v-btn @click="verifyCode" :loading="loading" large block tile color="primary">
                {{ $store.getters.translate("send") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import defineRulesFor from "../../ability";
import Styling from "./includable/Styling";

export default {
  name: "FullLogin",
  title: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : "Perluta",
  components: {
    Styling,
  },
  data: () => ({
    valid: true,
    password: "",
    show1: false,
    show_login: true,
    email: "",
    loading: false,
    message: null,
    info: {},
    support_email: null,
    logo: null,
    verification_code: null
  }),
  created() {
    this.$store.dispatch("refreshTranslations");
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.loading = true;
        this.$store.dispatch("login", { email: this.email, password: this.password })
        .then((login_response) => {
          this.loading = false;
          if(login_response.data.message === 'success') {
            this.userVerified();
          }
          else if(login_response.data.message === 'verify') {
            this.show_login = false;
          }
          else {
            this.$toasted.error(this.$store.getters.translate("weak_password"));
            this.$router.push('/resetpassword');
          }
        })
        .catch(() => {
          this.loading = false;
          this.message = this.$store.getters.translate("invalid_credentials");
        });
      }
    },
    verifyCode() {
      if(this.verification_code) {
        this.loading = true;
        this.$http
          .post(this.$store.getters.appUrl + "v2/verify", { user_email: this.email, verification_code: this.verification_code })
          .then((response) => {
            if(response.data === 'success') {
              this.userVerified();
            } else {
              this.$toasted.error(response.data.message);
              this.loading = false;
            }
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message);
            this.loading = false;
          });
      }
    },
    userVerified() {
      this.loading = true;
      this.$store.dispatch("verified").then((response) => {
        this.ability.update(defineRulesFor(response.data.permissions));
        if (this.getLanguage !== response.data.language) {
          this.$store.dispatch("setLanguage", response.data.language);
        }
        this.$store.dispatch("setSidebar", true);
        this.loading = false;
      })
      .catch((error) => {
        this.$toasted.error(error.response.data.message);
        this.loading = false;
      });
    },
    setInfo(data) {
      this.support_email = data.email;
      this.logo = data.logo;
    },
  },
  computed: {
    getLanguage: {
      get() {
        return this.$store.getters.getLanguage;
      },
      set(value) {
        this.$store.dispatch("setLanguage", value);
      },
    },
  },
};
</script>
